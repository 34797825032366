import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SlugQuery } from '@shared/models';
import {
	ClientUpdateModel,
	Event,
	EventLoadModel,
	EventUpdateModel,
	SimpleEvent,
} from '@consensus/shared/shared/event/domain';
import { Client, SimpleClient } from '@consensus/shared/shared/client/domain';
import { environmentToken } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class ScopeClient {
	readonly #apiServer = inject(environmentToken).server;
	readonly #httpClient = inject(HttpClient);

	loadClient({ id, slug }: SlugQuery) {
		if (id != null) {
			const params = new HttpParams().set('id', id);
			return this.#httpClient.get<Client>(`${this.#apiServer}/api/client`, {
				params,
			});
		} else {
			const params = new HttpParams().set('slug', slug);
			return this.#httpClient.get<Client>(`${this.#apiServer}/api/client`, {
				params,
			});
		}
	}

	loadAnonClient({ id, slug }: SlugQuery) {
		if (id != null) {
			const params = new HttpParams().set('id', id);
			return this.#httpClient.get<Client>(`${this.#apiServer}/api/client`, {
				params,
			});
		} else {
			const params = new HttpParams().set('slug', slug);
			return this.#httpClient.get<Client>(`${this.#apiServer}/api/client`, {
				params,
			});
		}
	}

	refreshClient() {
		return this.#httpClient.get<SimpleClient>(
			`${this.#apiServer}/api/client/refresh`
		);
	}

	updateClient(data: ClientUpdateModel) {
		return this.#httpClient.put<Client>(
			`${this.#apiServer}/api/client/${data.id}`,
			data
		);
	}

	assignClientPortalEvent(clientId: string, eventId: string) {
		return this.#httpClient.patch(
			`${this.#apiServer}/api/client/${clientId}/assign-portal`,
			{
				eventId,
			}
		);
	}

	unassignClientPortalEvent(clientId: string, eventId: string) {
		return this.#httpClient.patch(
			`${this.#apiServer}/api/client/${clientId}/unassign-portal`,
			{
				eventId,
			}
		);
	}

	loadEvent({ id, slug }: SlugQuery) {
		if (id != null) {
			return this.#httpClient.get<EventLoadModel>(
				`${this.#apiServer}/api/event/${id}`
			);
		}
		return this.#httpClient.get<EventLoadModel>(
			`${this.#apiServer}/api/event`,
			{
				params: { slug },
			}
		);
	}

	loadAnonEvent({ id, slug }: SlugQuery) {
		if (id != null) {
			return this.#httpClient.get<EventLoadModel>(
				`${this.#apiServer}/api/event/${id}/anon`
			);
		}
		return this.#httpClient.get<EventLoadModel>(
			`${this.#apiServer}/api/event/anon`,
			{
				params: { slug },
			}
		);
	}

	refreshEvent() {
		return this.#httpClient.get<SimpleEvent>(
			`${this.#apiServer}/api/event/refresh`
		);
	}

	updateEvent(data: EventUpdateModel) {
		return this.#httpClient.put<Event>(
			`${this.#apiServer}/api/event/current`,
			data
		);
	}

	deleteEvent(eventId: string) {
		return this.#httpClient.patch<Event>(
			`${this.#apiServer}/api/event/${eventId}/delete`,
			{}
		);
	}

	readEventTerms() {
		return this.#httpClient.patch(
			`${this.#apiServer}/api/event/terms/read`,
			{}
		);
	}

	uploadArPopulation(file: File) {
		const formData = new FormData();
		formData.append('File', file);
		return this.#httpClient.post<string>(
			`${this.#apiServer}/api/event/ar-population`,
			formData
		);
	}
}
